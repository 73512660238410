import { configPath } from "../../constants/constant";

const Header = () => {
    return (
        <header className="header header-white">
            <div className="top-bar">
                <div className="container">
                    <div className="top-info d-none d-lg-block">
                        <ul className="list-group list-group-horizontal list-unstyled">
                            <li className="list-item-inline">
                                <a href="www.reliableroamers.in" className="travel-des-label">
                                    Reliable Roamers Travels
                                </a>
                            </li>
                            <li className="list-item-inline">
                                <a href={`tel:${configPath.mobile_number}`}>
                                    <img src="/images/icons/phone-call.png" alt="Reliable Roamers Travels" />(+91) {configPath.mobile_number}<br /><br />
                                    (+91) {configPath.alt_number}
                                </a>
                            </li>
                            <li className="list-item-inline">
                                <a href={`mailto:${configPath.email}`}>
                                    <img src="/images/icons/email.png" alt="Reliable Roamers Travels" />{configPath.email}
                                </a>
                            </li>
                            <li className="list-item-inline">
                                <address className="office-address">
                                    <span>
                                        <img src="/images/icons/location.png" alt="Reliable Roamers Travels" />
                                        {configPath.office_address}
                                    </span>
                                </address>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;